<template>
  <CRow>
    <CCol col="12" xl="12">
      <CCard>
        <CCardHeader>
          <CRow>
              <CCol md="7">
                    <h4>Bank PayNow Pending</h4> 
              </CCol>              
              <CCol md="5">
                   <CInput label="Search" v-model="filter" @change="getFilterData" placeholder="Type to Search" horizontal />                   
              </CCol>
              <!--CCol md="2">                 
                    <vue-excel-xlsx 
                        style="color:green"                       
                        :data="data"
                        :columns="columns"
                        :filename="'Debit-Credit'"
                        :sheetname="'Debit-Credit'"
                        >
                        Download
                    </vue-excel-xlsx>                 
              </CCol-->
          </CRow>
        </CCardHeader><br/>
        <CCardBody> 

         <div class="text-center">
            <b-spinner variant="success" label="Spinning" v-if="spinner" ></b-spinner>
         </div>   

            <CRow>
              <CCol col="12" class="text-left">                   
                <CAlert v-bind:color="color" v-if="seen" id="alert">{{ msg }}</CAlert>
              </CCol> 
            </CRow>

                     <CRow v-if="formRollback"> 
                      <CCol sm="6" md="6">
                        <CCard>
                          <CCardHeader>
                            Form Rollback  
                          </CCardHeader>
                          <CCardBody> 
                               
                                                         
                                <CTextarea
                                    label="Reason"
                                    v-model="reason"  
                                    placeholder="Type Reason..."
                                    horizontal
                                    rows="2"               
                                />                                                               
                             
                              <b-row>
                                     <b-button-group>
                                          <b-button v-on:click="actionCancel()">Cancel</b-button>
                                          <b-button variant="success" v-on:click="ActionRollback()">Rollback</b-button>
                                     </b-button-group>         
                              </b-row>

                          </CCardBody>
                        </CCard>
                      </CCol>
                	</CRow>    


        <div class="table-responsive table">
          <b-table striped hover  :items="items" :fields="fields" v-model="list" :current-page="currentPage" :per-page="perPage" :filter="filter"> 

              <template v-slot:cell(createDate)="{ item }"> 
                {{ item.createDate | dateWithTime }}
              </template>

              <template v-slot:cell(movementType)="{ item }">
                    {{ item.movementType | changeLabel(item.transactionType) }}                   
              </template>

              <template v-slot:cell(actions)="{ item }">         

                <b-dropdown variant="info" text="Actions" size="md"> 
                  <router-link to="#" exact v-on:click.native="OpenFormRollback(item.transactionRef)" tag="b-dropdown-item" > Rollback </router-link>
                  <router-link to="#" exact v-on:click.native="ActionComplete(item.transactionRef)" tag="b-dropdown-item" > Complete </router-link>
                </b-dropdown> 

              </template>

          </b-table>
        </div>

        <div>
          <b-row>
            <b-col sm="3">
               <b-pagination size="md" :total-rows="this.items.length" :per-page="perPage" v-model="currentPage" />
            </b-col>       
            <b-col sm="9">
               <b-form-select :options="[{text:10,value:10},{text:20,value:20},{text:50,value:50},{text:100,value:100}]" v-model="perPage" style="width:100px;">
               </b-form-select>
            </b-col>
          </b-row>      
        </div>
          
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>
</template>

<script>
import TransactService from '@/api/TransactService.js';
import TheSidebarVue from '../../containers/TheSidebar.vue';

 export default {
    name: "bankpaynowpending",
    components: {},     
    data() {
      return {
        spinner:false,
        list:[],
        columns : [],
        data : [],
        items:[],             
        fields: [ 
          {
            key: 'createDate',
            label : 'Tr Date',
            sortable: true
          },   
          {
            key: 'transactionRef',
            label : 'Tr Reference',
            sortable: true
          },         
          {
            key: 'userIdentify',
            label : 'User ID',
            sortable: true
          },   
          {
            key: 'mobile',
            label : 'Mobile Number',
            sortable: true
          },                
          {
            key: 'currency',
            label : 'Currency',
            sortable: true
          },  
          {
            key: 'amount',
            label : 'Amount',
            sortable: true
          },
          {
            key: 'movementType',
            label : 'Movement Type',
            sortable: true
          },  
          {
            key: 'requestStatus',
            label : 'Status',
            sortable: true
          },
          {            
            key: 'actions',
            label: 'Actions'         
          }                   
        ],         
        currentPage: 1,
        perPage: 10,
        filter: null,
        formRollback:false,
        reason:"",
        trRef:"",
        seen:false,
        msg :'',
        color:''
      };
    },    
    filters: {
        upper(value){
          if(!value) return ''
          value = value.toString()
          return value.toUpperCase()
        },
        lower(value){
           if(!value) return ''
           value = value.toString()
           return value.toLowerCase()        
        },
        dateSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,10)            
        },        
        IdSubstr(value){
           if(!value) return ''
           value = value.toString()
           return value.substring(0,5) + '...'            
        },
        dateWithTime(value){
           if(!value) return ''
           var temp = value.toString().split(".");
           value = temp[0].replace("T"," ");
           return value;            
        },
        formatNum(value){ 
           if(!value) return 0  
           return value.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",")          
        },
        changeLabel(value,dbsTT){
            if(!value) return ''
            if(dbsTT==='BUYNOW') return 'BUYNOW_SGQR'
            //if(payM==='WALLET_IDR') return 'IDR_PAYNOW'
            else return value
        }
      },

    created () {        
       this.getBankPayNowPending();
    },
    methods: { 

      OpenFormRollback(trRef){
          this.trRef = trRef;
          this.formRollback = true;
      },

      actionCancel(){

          this.formRollback=false;
          this.trRef=""; this.reason="";
      },

      getFilterData(){          
         this.data  = this.list;
      },         

      setColumns(){
        var fields = this.fields; let row = {}; var cols = [];
        for (var key in fields) {           
           row['label'] = fields[key].label;
           row['field'] = fields[key].key;
           var temp = JSON.stringify(row); cols.push(temp);
        }        

        for (var key2 in cols) {               
           var row2 = JSON.parse(cols[key2]); this.columns.push(row2);
        }
      },      

      ActionRollback(){
         var result = confirm("Are you sure want to Rollback Transaction ?");
         if (result) {          
            let params = {}; var validate = [];
            params['movementRef'] = this.trRef;  params['reason'] = this.reason;  
            for (var key in params) {
                if(!params[key]) { validate.push(false); } else { validate.push(true); }
            } 
            
            if(!validate.includes(false)){  //console.log('submit');
                this.RollbackDbsPayNow(params); this.getBankPayNowPending(); this.formRollback=false;         
            } else {           
                this.msg   = 'Reason is Required!'; 
                this.color = 'warning'; this.seen  = true;
                setTimeout( () =>  this.seen=false , 5000);
            }    
         }         
      },

      ActionComplete(trRef){
          var result = confirm("Are you sure want to Complete Transaction ?");
          if (result) { 
            let params = {};  params['movementRef'] = trRef;
            this.CompleteP2PpPayNow(params);
            this.getBankPayNowPending();           
          }
      },  
      
      RollbackDbsPayNow: function(params) { 
           TransactService.RollbackDbsPayNow(params).then(resp => { 

              if(resp.message=='Success'){
                this.msg   = 'Rollback Transaction Successfully '; 
                this.color = 'success';  this.seen  = true;                
                //alert("Rollback Transaction Successfully!");
              } else {
                this.msg   =  !resp.data.message ? "Action Rollback Failed!" : resp.data.message; 
                this.color = 'warning'; this.seen  = true;                
              }
              setTimeout( () =>  this.seen=false , 5000);

           }, error => {
            console.log('error');    
          });
      }, 

      CompleteP2PpPayNow: function(params) { 
           TransactService.CompleteP2PpPayNow(params).then(resp => { 

              if(resp.message=='Success'){
                this.msg   = 'Completed Transaction Successfully '; 
                this.color = 'success';  this.seen  = true;                
                //alert("Completed Transaction Successfully!");
              } else {
                this.msg   =  !resp.data.message ? "Action Completed Transaction Failed!" : resp.data.message; 
                this.color = 'warning'; this.seen  = true;                 
              }
              setTimeout( () =>  this.seen=false , 5000);

           }, error => {
            console.log('error');    
          });
      }, 

      getBankPayNowPending: function() {
         TransactService.getBankPayNowPending().then(resp => {  //console.log(resp);
            this.items = resp;
         }, error => { this.loading = false; });
      }      

    }
 }; 

</script>

<style>
    .table{overflow-y:scroll; }
</style>